.TItle h2 {
  font-size: 2rem;
}
.Title hr {
  margin-bottom: 5%;
}

.skill {
  display: flex;
  flex-direction: column;
}

.skill-section {
  display: flex;
  flex-direction: column;
}

.skills {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
}

.skill-title {
  padding: 20px 0;
  text-align: left;
  font-size: 20px;
  
  @media screen and (min-width: 768px) {
    font-size: 24px;
  }
}

.Skill {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: center;
  width: 80px;

  @media screen and (min-width: 563px) {
    width: 100px;
  }

  @media screen and (min-width: 956px) {
    width: 128px;
  }
}
