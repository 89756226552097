.Footer {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 5%;
  background-color: rgb(0, 0, 0);
}

.Footer p {
  margin: 4px 0;
  font-size: small;
}
