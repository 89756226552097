.project {
  margin-bottom: 5vh;
}
.projectTitle {
  font-weight: bold;
  margin: 10px 0;
  font-size: larger;
  text-align: left;
}
.project p:last-child {
  font-size: medium;
}

.projectBtn button {
  margin-right: 2%;
  padding: 10px;
  background-color: black;
  border: 1px solid white;
  color: white;
  border-radius: 5%;
}
@media only screen and (max-width: 368px) {
  .projectBtn button:first-child {
    margin-bottom: 10px;
    width: 100%;
  }
}

.projectBtn button:hover {
  background-color: white;
  border: 1px solid white;
  color: rgb(0, 0, 0);
  border-radius: 5%;
  transition: ease-in;
  transition: all 0.2s ease-in-out;
}

.projectBtn a {
  text-decoration: none;
}

.chips {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  padding-bottom: 20px;
}