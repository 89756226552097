.ContainerGlobal {
  background-color: rgb(0, 0, 0);
}
.Container {
  display: flex;
  flex-direction: column;
  background-color: rgb(0, 0, 0);
  padding: 2% 20%;
  justify-content: center;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: white;
  text-align: left;

  @media screen and (max-width: 637px) {
    padding: 2% 10%;
  }
}
