.LangsOptions {
    display: flex;
    justify-content: flex-end;
    padding-right: 10%;
    margin-top: 5%;
}

.LangsOptions  button {
    border: 1px solid white;
    background-color: black;
    color: white;
    padding: 1vw;
    font-size: medium
}
.LangsOptions  button:hover {
    transition: 0.3s;
    border: 1px solid white;
    background-color: white;
    color: black;
    padding: 1vw;
}

.LangsOptions button:first-child{
    margin-right: 2%;
}

.LangsOptions button:focus{
    border: 1px solid white;
    background-color: white;
    color: black;
    padding: 1vw;
    
}
