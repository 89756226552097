.Header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.MyPicture {
  display: flex;
}
.MyPicture img {
  width: 100%;
  height: auto;
  border-radius: 50%;
  display: flex;
}

.AboutMe {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 10px;
  border-left: 5px solid white;
  border-bottom: 5px solid white;
  border-right: 1px solid white;
  border-top: 1px solid white;
}

.AboutMe h1 {
  font-size: 2rem;
  margin: 0;
}

.Resume {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.Resume p {
  margin: 0px;
  padding: 0px;
  font-size: 1rem;
}
